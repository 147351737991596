import React from "react";
import Jobs from "../../components/jobs";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import cms from "../../components/cms/cms";

const UKPage = () =>
  <Layout>
    <SEO
      keywords={[`${cms.keyword} jobs united kingdom`, `${cms.keyword} jobs london`, `${cms.keyword} jobs uk`, `${cms.keyword} developer jobs united kingdom`, `${cms.keyword} developer jobs london`, `${cms.keyword} developer jobs uk`, `remote ${cms.keyword} jobs united kingdom`, `remote ${cms.keyword} jobs uk`, `${cms.keyword} software engineering jobs united kingdom`, `${cms.keyword} software engineering jobs london`, `${cms.keyword} software engineering jobs uk`]}
      title={`${cms.keyword} Jobs in the United Kingdom`}
      description={`Looking for a ${cms.keyword} Developer job in the United Kingdom? ${cms.siteUrl} is exclusively dedicated to ${cms.keyword} jobs. Find ${cms.keyword} jobs in London, Cambridge and other places in the UK.`}
    />
    <Jobs countryProp={["GB"]} />
  </Layout>

export default UKPage;